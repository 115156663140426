<template>
  <div class="intro">
    <h3 class="sub-title">About ASCVTS 2024</h3>
    <div class="table-responsive">
      <div class="table-tr">
        <div class="lt"><span>Congress Title</span></div>
        <div class="rt">
          <span>the 32<sup>nd</sup> Annual Meeting of the Asian Society for Cardiovascular and Thoracic Surgery</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Date</span></div>
        <div class="rt">
          <span>23(Thursday) - 26(Sunday) May 2024</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Venue</span></div>
        <div class="rt">
          <span>The Wuhan International Expo Center</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Theme</span></div>
        <div class="rt">
          <span class="subTitle">Cooperation, Innovation, Excellence</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Hosted by</span></div>
        <div class="rt">
          <span>The Asian Society for Cardiovascular and Thoracic Surgery (ASCVTS)</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Organized by</span></div>
        <div class="rt">
          <span>Union Hospital, Tongji Medical College, Huazhong University of Science and Technology</span>
          <span>China Medicine Education Association</span>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.intro {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .table-responsive {
    border-top: 3px solid #19317e;
    .table-tr {
      display: flex;
      font-size: 16px;
      .lt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        flex-shrink: 0;
        background-color: #f8f9fa;
        padding: 13px;
        border: 1px solid #dee2e6;
        border-top: none;
      }
      .rt {
        flex: 1;
        font-family: "Akrobat Regular";
        border-bottom: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        span {
          display: block;
          padding: 13px;
          & + span {
            border-top: 1px solid #dee2e6;
          }
          &.subTitle {
            font-family: "Akrobat black";
            background: linear-gradient(73deg, #bc395c 20%, #3e437d 100%);
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            position: relative;
            font-style: italic;
          }
        }
        &>div {
          display: flex;
          border-bottom: 1px solid #dee2e6;
          .r-lt {
            padding: 13px;
            flex: 0 0 60%;
            border-right: 1px solid #dee2e6;
          }
          .r-rt {
            padding: 13px;
            flex: 0 0 40%;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      @media (max-width:575px) {
        font-size: 15px;
        .lt {
          width: 30%;
        }
      }
    }
  }
}
</style>
